// extracted by mini-css-extract-plugin
export var Sidebar = "sidebar-module--Sidebar--2yUPv";
export var Sidebar_opened = "sidebar-module--Sidebar_opened--19Q5u";
export var SidebarSwipeControl = "sidebar-module--SidebarSwipeControl--3yKuN";
export var SidebarAside = "sidebar-module--SidebarAside--21WCd";
export var SidebarAside__article = "sidebar-module--SidebarAside__article--2Cvs3";
export var SidebarAside__events = "sidebar-module--SidebarAside__events--3m-E9";
export var SidebarAside__tags = "sidebar-module--SidebarAside__tags--2YzV-";
export var SidebarAside__contacts = "sidebar-module--SidebarAside__contacts--3u_mW";
export var SidebarAside__feedback = "sidebar-module--SidebarAside__feedback--GoZ9e";
export var SidebarControl = "sidebar-module--SidebarControl--KGoa9";
export var SidebarControl_active = "sidebar-module--SidebarControl_active--3JCp3";
// extracted by mini-css-extract-plugin
export var SCard = "simple-card-module--SCard--2vv40";
export var SCard__body = "simple-card-module--SCard__body--ZYU_n";
export var SCard__imgLink = "simple-card-module--SCard__imgLink--3c5sK";
export var SCard__img = "simple-card-module--SCard__img--8Lk9n";
export var SCard__content = "simple-card-module--SCard__content--2OhED";
export var SCard__header = "simple-card-module--SCard__header--3l08O";
export var SCard__title = "simple-card-module--SCard__title--Lnrh0";
export var SCard__description = "simple-card-module--SCard__description--3rgnm";
export var SCard__tags = "simple-card-module--SCard__tags--1_-ai";
export var SCard__duration = "simple-card-module--SCard__duration--17-aq";